import React, { useCallback, useMemo } from 'react';
import { BrowserRouter } from 'react-router-dom';
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import { HelmetProvider } from 'react-helmet-async';

import Navbar from './Components/Navigation/NavBar';
import Footer from './Components/Footer/Footer';

import { AuthProvider } from './utils/AuthContext';
import { ToastProvider } from "./@/components/ui/toast"

import AppRoutes from './AppRoutes';
import './App.css';

// Move outside component - singleton instance
const queryClient = new QueryClient();

const App: React.FC = () => {
  const [activeLanguage, setActiveLanguage] = React.useState('el'); // ['en', 'el']

  // Memoize handler to prevent recreations
  const handleLanguageChange = useCallback((language: string) => {
    setActiveLanguage(language);
  }, []);

  return (
    <BrowserRouter>
      <HelmetProvider>
        <ToastProvider>
          <AuthProvider>
            <QueryClientProvider client={queryClient}>
              <Navbar activeLanguage={activeLanguage} setActiveLanguage={handleLanguageChange} />
              <AppRoutes activeLanguage={activeLanguage} setActiveLanguage={handleLanguageChange} />
              <Footer activeLanguage={activeLanguage} />
            </QueryClientProvider>
          </AuthProvider>
        </ToastProvider>
      </HelmetProvider>
    </BrowserRouter>
  );
};
export default React.memo(App);
