import React, { useEffect } from 'react';
import HeroSection from '../../Components/Home/HomeHero/HomeHero';
import HomeLoveTheRide from '../../Components/Home/HomeLoveTheRide/HomeLoveTheRide';
import DownloadOurApp from '../../Components/Home/DownloadOurApp/DownloadOurApp';
import HomeFleet from '../../Components/Home/HomeFleet/HomeFleet';
import HomeNews from '../../Components/Home/HomeNews/HomeNews';
import HomeCarousel from '../../Components/Home/HomeCarousel/CarouselWrapper';
import MetatagsHelmet from '../../utils/MetatagsHelmet/MetatagsHelmet';

interface HomeProps {
    activeLanguage: string;
}

const Home: React.FC<HomeProps> = (props) => {
    const { activeLanguage } = props;
    const metadata = {
        el: {
        title: "taxi.gr | Η νέα εφαρμογή ταξί στην Ελλάδα",
        description: "Καλωσορίσατε στην taxi.gr, την νέα εφαρμογή ταξί στην Ελλάδα. Γρήγορες παραλαβές, αξιόπιστοι οδηγοί και ανταγωνιστικές τιμές.",
        keywords: []
        },
        en: {
        title: "taxi.gr - Home Page | The New Taxi App in Greece",
        description: "Welcome to taxi.gr, the new taxi app in Greece. Fast pickups, reliable drivers, and competitive prices.",
        keywords: []
        }
    };

    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);

    const currentLang = activeLanguage as keyof typeof metadata;
    
    return (
        <>
            <MetatagsHelmet 
            activeLanguage={activeLanguage}
            pageTitle={metadata[currentLang].title}
            pageDescription={metadata[currentLang].description}
            additionalKeywords={metadata[currentLang].keywords}
            pagePath="/"
            />
            <main>
                <HeroSection activeLanguage={activeLanguage} />
                <HomeCarousel activeLanguage={activeLanguage} />
                <HomeFleet activeLanguage={activeLanguage} />
                <HomeLoveTheRide activeLanguage={activeLanguage} />
                <DownloadOurApp activeLanguage={activeLanguage} />
                <HomeNews activeLanguage={activeLanguage} />
            </main>
        </>
    );
};

export default Home;