import React from 'react';
import DefaultLanguageInterface from '../../interfaces';
import MetatagsHelmet from '../../utils/MetatagsHelmet/MetatagsHelmet';
import NewsHomeHeader from '../../Components/Misc/News/NewsHomeHeader/NewsHomeHeader';
import NewsAllNews from '../../Components/Misc/News/NewsAllNews/NewsAllNews';
import DownloadOurApp from '../../Components/Home/DownloadOurApp/DownloadOurApp';

const NewsHome:React.FC<DefaultLanguageInterface> = ({activeLanguage}) => {
    const metadata = {
        el: {
        title: "taxi.gr - Νέα και τύπος | Όλες οι αναρτήσεις για το taxi.gr",
        description: "Όλες οι ενημερώσεις από την ομάδα του taxi.gr, καθώς και αναρτήσεις από ανεξάρτητους συνεργάτες και τον τύπο.",
        keywords: ["νέα", "ενημερώσεις", "τύπος", "ανακοινώσεις taxi", "δελτία τύπου", "νέα ταξί", "ειδήσεις μεταφορών", "εξελίξεις taxi.gr"]
        },
        en: {
        title: "taxi.gr - News and Press | All posts about taxi.gr",
        description: "All updates from the taxi.gr team, as well as posts from independent collaborators and the press.",
        keywords: ["news", "updates", "press", "taxi announcements", "press releases", "taxi news", "transportation news", "taxi.gr developments"]
        }
    };

    const currentLang = activeLanguage as keyof typeof metadata;

    return (
        <>
            <MetatagsHelmet 
            activeLanguage={activeLanguage}
            pageTitle={metadata[currentLang].title}
            pageDescription={metadata[currentLang].description}
            additionalKeywords={metadata[currentLang].keywords}
            pagePath="/news"
            />
            <main>
                <NewsHomeHeader activeLanguage={activeLanguage} />
                <NewsAllNews activeLanguage={activeLanguage} />
                <DownloadOurApp activeLanguage={activeLanguage} />
            </main>
        </>
    )
}

export default NewsHome;