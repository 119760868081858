import React from 'react';
import { Helmet } from 'react-helmet-async';
import taxi from '../../images/thumbnails/taxi_img.png';

interface MetatagsHelmetProps {
  activeLanguage: string;
  pageTitle?: string;
  pageDescription?: string;
  standardKeywords?: string[]; // Override standard keywords
  additionalKeywords?: string[]; // Add page-specific keywords
  pageImage?: string;
  pagePath?: string;
  pageType?: string;
}

const MetatagsHelmet: React.FC<MetatagsHelmetProps> = ({
  activeLanguage,
  pageTitle,
  pageDescription,
  standardKeywords: overrideStandardKeywords,
  additionalKeywords = [],
  pageImage = taxi,
  pagePath = "",
  pageType = "website"
}) => {
  // Default content based on language
  const defaults = {
    el: {
      title: "taxi.gr - Η νέα εφαρμογή ταξί στην Ελλάδα",
      description: "taxi.gr - Η νέα εφαρμογή ταξί στην Ελλάδα με αξιόπιστη εξυπηρέτηση, γρήγορες παραλαβές και ανταγωνιστικές τιμές.",
      keywords: ["ταξί", "Ελλάδα", "εφαρμογή ταξί", "μεταφορές", "Αθήνα", "μετακινήσεις", "μετακίνηση", "taxi.gr", "taxigr"]
    },
    en: {
      title: "taxi.gr - The Greek Taxi App",
      description: "taxi.gr - The new taxi app in Greece with reliable service, fast pickups, and competitive prices.",
      keywords: ["taxi", "Greece", "taxi app", "transportation", "Athens", "ride-sharing", "taxi.gr", "taxigr"]
    }
  };

  const currentLang = activeLanguage as keyof typeof defaults;

  const title = pageTitle || defaults[currentLang].title;
  const description = pageDescription || defaults[currentLang].description;
  // Use provided standard keywords or default to our predefined list
  const standardKeywords = overrideStandardKeywords || defaults[currentLang].keywords;
  // Combine all keywords and join with commas
  const allKeywords = [...standardKeywords, ...additionalKeywords].join(", ");
  
  // Base URL
  const baseUrl = "https://taxi.gr";
  
  // Full URL for the current page
  const fullUrl = activeLanguage === 'el' 
    ? `${baseUrl}${pagePath}` 
    : `${baseUrl}/en${pagePath}`;

  // Alternate language URLs
  const elUrl = activeLanguage === 'en' ? `${baseUrl}${pagePath}` : fullUrl;
  const enUrl = activeLanguage === 'el' ? `${baseUrl}/en${pagePath}` : fullUrl;

  return (
    <Helmet>
      {/* Basic meta tags */}
      <title>{title}</title>
      <meta name="description" content={description} />
      <meta name="keywords" content={allKeywords} />
      
      {/* Language information */}
      <html lang={activeLanguage} />
      
      {/* Canonical URL */}
      <link rel="canonical" href={fullUrl} />
      
      {/* Language alternates */}
      <link rel="alternate" hrefLang="el" href={elUrl} />
      <link rel="alternate" hrefLang="en" href={enUrl} />
      
      {/* Open Graph / Social Media */}
      <meta property="og:url" content={fullUrl} />
      <meta property="og:type" content={pageType} />
      <meta property="og:title" content={title} />
      <meta property="og:description" content={description} />
      <meta property="og:image" content={`${baseUrl}${pageImage}`} />
      <meta property="og:site_name" content="taxi.gr" />
      
      {/* Twitter Card */}
      <meta name="twitter:card" content="summary_large_image" />
    </Helmet>
  );
};

export default MetatagsHelmet;