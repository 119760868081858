import React, { useEffect } from "react";
import DefaultLanguageInterface from "../../interfaces";
import MetatagsHelmet from '../../utils/MetatagsHelmet/MetatagsHelmet';
import DriverFormNew from "../../Components/DriverFormNew/DriverFormNew";
import { useLocation } from 'react-router-dom';

const DriversFormNewPage:React.FC<DefaultLanguageInterface> = ({activeLanguage}) => {
    const location = useLocation();
    const { email, phoneNumber, city } = location.state || { email: '', phone_number: '', city: '' };
    const metadata = {
        el: {
            title: "taxi.gr - Φόρμα Εγγραφής Οδηγού | Ξεκίνα να κερδίζεις έως 7€ επιπλέον ανά διαδρομή",
            description: "Συμπλήρωσε τη φόρμα εγγραφής για να γίνεις οδηγός στο taxi.gr. Απόλαυσε 24/7 υποστήριξη, ανταγωνιστικές απολαβές και πλήρη ευελιξία χωρίς δεσμεύσεις αποκλειστικότητας. Η διαδικασία είναι απλή και γρήγορη, ξεκίνησε άμεσα να δέχεσαι κρατήσεις και να αυξάνεις τα έσοδά σου.",
            keywords: ["εγγραφή οδηγού", "φόρμα εγγραφής ταξί", "γίνε οδηγός taxi", "αίτηση οδηγού ταξί", "εγγραφή ταξιτζή", "συνεργασία με taxi.gr", "δουλειά οδηγός ταξί", "εγγραφή οδηγού taxi.gr"]
        },
        en: {
            title: "taxi.gr - Driver Registration Form | Start earning up to 7€ extra per trip",
            description: "Complete the registration form to become a driver with taxi.gr. Enjoy 24/7 support, competitive earnings and complete flexibility with no exclusivity requirements. The process is simple and quick - start accepting bookings and increasing your income immediately.",
            keywords: ["driver registration", "taxi driver application", "become a taxi driver", "taxi driver signup", "join taxi.gr", "taxi driver job", "driver onboarding", "register as taxi driver"]
        }
    }
    
    
    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);

    const currentLang = activeLanguage as keyof typeof metadata;

    return (
        <>
        <MetatagsHelmet 
        activeLanguage={activeLanguage}
        pageTitle={metadata[currentLang].title}
        pageDescription={metadata[currentLang].description}
        additionalKeywords={metadata[currentLang].keywords}
        pagePath="/drivers-form"
        />
            <main className="">
                <DriverFormNew activeLanguage={activeLanguage} email={email} phone_number={phoneNumber} city={city} />
            </main>
        </>
    )
}

export default DriversFormNewPage;