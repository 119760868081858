import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import LogoSvg from '../../../images/LogoSvg';
import DefaultLanguageInterface from '../../../interfaces';
import greekLanguage from '../../../locales/gr.json';
import englishLanguage from '../../../locales/en.json';
import grLoc from '../../../images/Taxi_icons/svgs/gr_loc.svg'
import ActionButton from '../../ActionButton/ActionButton';
import Switch from '../../../utils/Switch/Switch';
import { ACTIVE_URL } from '../../../constants';
import { isValidEmail, isValidPhoneNumber } from '../../../utils/InputField/InputFieldValidation';
import {
    Select,
    SelectContent,
    SelectGroup,
    SelectItem,
    SelectTrigger,
    SelectValue,
} from "../../../@/components/ui/select";
import SectionTitle from '../../../utils/SectionTitle/SectionTitle';
import DriverHeaderImg from './DriverHeaderImg/DriverHeaderImg';
import useTrackEvent from '../../../utils/UseTrackEvent/useTrackEvent';

const DriverHeader: React.FC<DefaultLanguageInterface> = ({ activeLanguage }) => {
    const navigate = useNavigate();

    const [city, setCity] = React.useState<string>('Αθήνα');
    const [isTACCheckboxChecked, setIsTACCheckboxChecked] = React.useState<boolean>(true);
    const languageData = activeLanguage === 'el' ? greekLanguage : englishLanguage;

    const [email, setEmail] = useState('');
    const [emailFieldTouched, setEmailFieldTouched] = useState(false);
    const [isEmailFieldFocused,] = useState(false);

    const [phoneFieldTouched, setPhoneFieldTouched] = useState(false);
    const [isPhoneFieldFocused, setIsPhoneFieldFocused] = useState(false);
    const [phoneNumber, setPhoneNumber] = useState('');

    const [submitPressed, setSubmitPressed] = useState<boolean>(false);
    const trackEvent = useTrackEvent();

    const phoneBlurHandler = () => {
        setPhoneFieldTouched(true);
        setIsPhoneFieldFocused(false);
    }

    const postInitialDriverData = async () => {
        try {
            const response = await fetch(`${ACTIVE_URL}/api/post_driver_initial_data/`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify({
                    email: email,
                    phone_number: phoneNumber,
                    city: city
                })
            });
            if (response.ok) {
                console.log("Successfully posted initial driver data.");
            } else {
                console.error("Failed to post initial driver data.");
            }
        } catch (error) {
            if (error instanceof Error) {
                console.error(error.message);
            } else {
                console.error("An unknown error occurred.");
            }
        }
    }

    const handleSubmit = () => {
        setSubmitPressed(true);
        if (!isValidEmail(email) || !isValidPhoneNumber(phoneNumber) || !isTACCheckboxChecked) {
            return;
        }
        trackEvent('StartRegistration', 'form', 'driver_page', {
            city: city,
            language: activeLanguage
        });
        postInitialDriverData();
        navigate('/drivers-form', { state: { email, phoneNumber, city } });
    };

    useEffect(() => {
        const hash = window.location.hash;
        if (hash === '#drivers-form') {
            const element = document.getElementById('drivers-form');
            if (element) {
                setTimeout(() => {
                    element.scrollIntoView({ behavior: 'smooth' });
                }, 100);
            }
        }
    }, []);

    return (
        <div className=''>
            <DriverHeaderImg activeLanguage={activeLanguage} />
            <div className='w-screen lg:w-1/2 mt-[-10px] lg:animate-fadeUp lg:absolute lg:top-0 lg:right-drivers-form-right md:mt-[max(9rem,20vh)]'>
                <div className='absolute top-[-30px]' id='drivers-form'></div>
                <div className='lg:mr-[25px] flex lg:justify-end z-50'>
                    <div className='bg-white rounded-lg py-8 lg:py-10 px-6 lg:shadow-md w-full lg:w-[500px] text-primary select-none shadow-lg'>
                        <SectionTitle title={languageData.driver.driver_form_intro_title} className='lg:text-start' />
                        <form className='flex flex-col gap-8'>
                            <div className='w-full flex flex-col gap-1'>
                                <label htmlFor="email" className='text-sm font-bold ml-1'>Email</label>
                                <input
                                    type="email"
                                    id="email"
                                    placeholder={languageData.driver.driver_form_intro_email_placeholder}
                                    className={`w-full text-[0.95rem] h-12 pl-2 pr-2 text-neutral-900 rounded-md outline-none bg-neutral-100 border ${isValidEmail(email) && emailFieldTouched ? 'border-green-400' : (!emailFieldTouched ? 'border-neutral-200' : 'border-red-500')} ${!isValidEmail(email) && submitPressed ? 'border-red-500' : ''}  focus:pl-[6.68px] focus:border-2 focus:border-main focus:shadow-md`}
                                    value={email}
                                    onBlur={() => setEmailFieldTouched(true)}
                                    onFocus={() => setEmailFieldTouched(false)}
                                    onChange={(e) => setEmail(e.target.value)}
                                />
                            </div>
                            {((!isValidEmail(email) && emailFieldTouched && !isEmailFieldFocused) || (submitPressed && !isValidEmail(email))) && (
                                <div className="absolute ml-0 pl-1  mt-16 pt-3 text-xs text-red-500">
                                    {languageData.driver.driver_form_intro_email_error}
                                </div>
                            )}
                            <div className='w-full flex flex-col gap-1'>
                                <label htmlFor="tel" className='text-sm font-bold ml-1'>{languageData.driver.driver_form_intro_phone_title}</label>
                                <div className='flex gap-2 w-full'>
                                    <div className='h-12 w-1/6 min-w-[60px] text-black rounded-md outline-none bg-neutral-100 border flex items-center justify-center gap-1'>
                                        <img src={grLoc} alt="greek flag" className='h-4' />
                                        <div className='text-neutral-900 text-base lg:text-lg'>
                                            +30
                                        </div>
                                    </div>
                                    <input
                                        type="tel"
                                        id='tel'
                                        placeholder={languageData.driver.driver_form_intro_phone_placeholder}
                                        onBlur={() => phoneBlurHandler()}
                                        className={`w-5/6 text-[0.95rem] h-12 px-2 text-black rounded-md outline-none bg-neutral-100 border ${isValidPhoneNumber(phoneNumber) && phoneFieldTouched ? 'border-green-400' : (!phoneFieldTouched ? 'border-neutral-200' : (!isPhoneFieldFocused ? 'border-red-500' : ''))} ${!isValidPhoneNumber(phoneNumber) && submitPressed ? 'border-red-500' : ''}   focus:pl-[6.68px] focus:border-2 focus:border-main focus:shadow-md`}
                                        value={phoneNumber}
                                        onInput={(e) => {
                                            const target = e.target as HTMLInputElement;
                                            let value = target.value.replace(/\D+/g, '');
                                            if (value.length > 10) {
                                                value = value.substring(0, 10);
                                            }
                                            target.value = value;
                                        }}
                                        onFocus={() => setIsPhoneFieldFocused(true)}
                                        onChange={(e) => setPhoneNumber(e.target.value)}
                                    />
                                    {((!isValidPhoneNumber(phoneNumber) && phoneFieldTouched && !isPhoneFieldFocused) || (submitPressed && !isValidPhoneNumber(phoneNumber))) && (
                                        <div className="absolute ml-20 pl-1.5 mt-12 pt-1 text-xs text-red-500">
                                            {languageData.driver.driver_form_intro_phone_error}
                                        </div>
                                    )}
                                </div>
                            </div>
                            <div className='w-full flex flex-col gap-1 select-none'>
                                <label htmlFor="city" className='text-sm font-bold ml-1'>{languageData.driver.driver_form_intro_city_title}</label>
                                <Select onValueChange={(option) => setCity(option)} value={city} >
                                    <SelectTrigger className=" outline-none h-12 text-neutral-600 ">
                                        <SelectValue placeholder="Επίλεξε Πόλη" />
                                    </SelectTrigger>
                                    <SelectContent className='w-full '>
                                        <SelectGroup className=''>
                                            <SelectItem key='1' value='Αθήνα' className='hover:text-main'>
                                                <div className='hover:text-main'>
                                                    {activeLanguage === 'el' ? 'Αθήνα' : 'Athens'}
                                                </div>
                                            </SelectItem>
                                            <SelectItem key='2' value='Θεσσαλονίκη' >
                                                <div className='hover:text-main'>
                                                    {activeLanguage === 'el' ? 'Θεσσαλονίκη' : 'Thessaloniki'}
                                                </div>
                                            </SelectItem>
                                            <SelectItem key='3' value='Πάτρα' >
                                                <div className='hover:text-main'>
                                                    {activeLanguage === 'el' ? 'Πάτρα' : 'Patra'}
                                                </div>
                                            </SelectItem>
                                            <SelectItem key='4' value='Ιωάννινα' >
                                                <div className='hover:text-main'>
                                                    {activeLanguage === 'el' ? 'Ιωάννινα' : 'Ioannina'}
                                                </div>
                                            </SelectItem>
                                            <SelectItem key='5' value='Κρήτη' >
                                                <div className='hover:text-main'>
                                                    {activeLanguage === 'el' ? 'Κρήτη' : 'Crete'}
                                                </div>
                                            </SelectItem>
                                        </SelectGroup>
                                    </SelectContent>
                                </Select>
                            </div>
                            <div className='text-xs text-neutral-500 flex flex-col gap-4'>
                                <div className='flex gap-3 items-center '>
                                    <Switch checked={isTACCheckboxChecked} onChange={() => setIsTACCheckboxChecked(!isTACCheckboxChecked)} activeColorClass='bg-main' inactiveColorClass='bg-red-500' wrapperClass='w-12 h-6 ' />

                                    <div className='text-neutral-700'>
                                        {/* {activeLanguage === 'el' ? 'Με την εγγραφή σας συμφωνείτε με τους Όρους Χρήσης και την Πολιτική Απορρήτου μας, δεσμεύεστε να συμμορφώνεστε με τις υποχρεώσεις σύμφωνα με την Ευρωπαϊκή Ένωση και την τοπική νομοθεσία και να παρέχετε μόνο νόμιμες υπηρεσίες και περιεχόμενο στην πλατφόρμα taxi.gr' : 'By signing up, you agree to our Terms of Service and Privacy policy, commit to comply with obligations under the European Union and local legislation and provide only legal services and content on the taxi.gr platform.'} */}
                                        {activeLanguage === 'el' ? 'Συμφωνείτε με τους Όρους Χρήσης, την Πολιτική Απορρήτου και δεσμεύεστε να παρέχετε μόνο νόμιμες υπηρεσίες και περιεχόμενο στην πλατφόρμα taxi.gr' : 'By signing up, you agree to our Terms of Service and Privacy policy, commit to comply with obligations under the European Union and local legislation and provide only legal services and content on the taxi.gr platform.'}
                                    </div>
                                </div>
                                {submitPressed && !isTACCheckboxChecked && (
                                    <div className=" text-xs text-red-500 mb-[-12px]">
                                        {languageData.driver.driver_form_intro_terms_error}
                                    </div>
                                )}
                            </div>
                            <div>
                                <ActionButton className='btn btn-primary bg-main py-[0.565rem] px-6 rounded-md hover:bg-secondary duration-10 text-white' activeLanguage={activeLanguage} text_english={'Sign Up'} text_greek={'Κάνε Εγγραφή'} onClick={handleSubmit} />
                            </div>
                        </form>
                    </div>

                </div>
            </div>
        </div>
    )

}

export default DriverHeader;
