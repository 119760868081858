import React from 'react';
import TermsAndConditionsHero from '../../../Components/Misc/TermsAndConditions/TermsAndConditionsHero/TermsAndConditionsHero';
import TermsAndConditionsContent from '../../../Components/Misc/TermsAndConditions/TermsAndConditionsContent/TermsAndConditionsContent';
import DefaultLanguageInterface from "../../../interfaces";
import MetatagsHelmet from '../../../utils/MetatagsHelmet/MetatagsHelmet';

const TermsAndConditionsPage:React.FC<DefaultLanguageInterface> = ({ activeLanguage }) => {
    const metadata = {
        el: {
            title: "taxi.gr - Όροι και Προϋποθέσεις | Γενικοί Όροι & Προϋποθέσεις Χρήσης Υπηρεσιών",
            description: "Διαβάστε τους όρους και τις προϋποθέσεις χρήσης της πλατφόρμας taxi.gr. Περιλαμβάνονται αναλυτικά οι κανόνες χρήσης της εφαρμογής, οι υποχρεώσεις των οδηγών και των επιβατών, οι όροι πληρωμών και οι νομικές δεσμεύσεις που διέπουν τις υπηρεσίες μας.",
            keywords: ["όροι χρήσης", "προϋποθέσεις", "νομικοί όροι", "συμφωνία χρήστη", "κανονισμοί ταξί", "συμβόλαιο υπηρεσιών", "όροι μεταφοράς", "δικαιώματα και υποχρεώσεις", "καταγγελία συμφωνίας"]
        },
        en: {
            title: "taxi.gr - Terms and Conditions | Service Agreement",
            description: "Read the terms and conditions for using the taxi.gr platform. Includes detailed rules for app usage, driver and passenger obligations, payment terms, and the legal commitments governing our services.",
            keywords: ["terms of use", "conditions", "legal terms", "user agreement", "taxi regulations", "service contract", "transportation terms", "rights and obligations", "agreement termination"]
        }
    }

    const currentLang = activeLanguage as keyof typeof metadata;
    
    return (
        <>
        <MetatagsHelmet 
        activeLanguage={activeLanguage}
        pageTitle={metadata[currentLang].title}
        pageDescription={metadata[currentLang].description}
        additionalKeywords={metadata[currentLang].keywords}
        pagePath="/terms-and-conditions"
        />
            <div>
                <TermsAndConditionsHero activeLanguage={activeLanguage} />
                <TermsAndConditionsContent activeLanguage={activeLanguage} />
            </div>
        </>
    )
}


export default TermsAndConditionsPage;