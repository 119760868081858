import React, { useEffect, useRef, useState } from 'react';
import { useParams } from 'react-router-dom';
import { ACTIVE_URL } from '../../constants';
import DefaultLanguageInterface from '../../interfaces';
import HTMLRenderer from '../../utils/HTMLRenderer';
import SkeletonLoader from '../../utils/SkeletonLoader/SkeletonLoader';
import SectionWrapper from '../../utils/SectionWrapper/SectionWrapper';
import SectionTitle from '../../utils/SectionTitle/SectionTitle';
import MetatagsHelmet from '../../utils/MetatagsHelmet/MetatagsHelmet';

type NewsItem = {
    grTitle: string;
    enTitle: string;
    grDescription: string;
    enDescription: string;
    image: string;
    date: string;
};

const SingleNewsPage: React.FC<DefaultLanguageInterface> = ({ activeLanguage }) => {
    const { slug } = useParams();
    const [newsItem, setNewsItem] = useState<NewsItem>({ grTitle: '', enTitle: '', grDescription: '', enDescription: '', image: '', date: '' });
    const loading = useRef(true);
    const [metadata, setMetadata] = useState({
        title: '',
        description: '',
        keywords: [] as string[],
    });

    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);

    useEffect(() => {
        const fetchResponses = async () => {
            try {
                const response = await fetch(`${ACTIVE_URL}/api/get_news/${slug}`, {
                    method: 'GET',
                    headers: {
                        'Content-Type': 'application/json',
                    }
                });

                if (response.ok) {
                    const data = await response.json();

                    setNewsItem({
                        grTitle: data.gr_title,
                        enTitle: data.en_title,
                        grDescription: data.gr_description,
                        enDescription: data.en_description,
                        image: data.image_data,
                        date: data.date
                    });

                    loading.current = false;
                    
                    // Generate keywords array from title
                    // Split the title into words, filter out short words, and add some standard keywords
                    const titleWords = title.toLowerCase().split(/\s+/).filter(word => word.length > 3);
                    const keywordsArray = ['news', 'article', ...titleWords];
                    setMetadata({
                        title: activeLanguage === 'el' ? data.gr_title : data.en_title,
                        description: activeLanguage === 'el' 
                            ? stripHtml(data.gr_description).substring(0, 160) 
                            : stripHtml(data.en_description).substring(0, 160),
                        keywords: keywordsArray,
                    });
                } else {
                    console.error('Failed to fetch responses');
                }
            } catch (error) {
                console.error('Error fetching responses:', error);
            }
        }
        fetchResponses();
    }, [slug, activeLanguage]);

    // Helper function to strip HTML tags for meta description
    const stripHtml = (html: string) => {
        const tempDiv = document.createElement('div');
        tempDiv.innerHTML = html;
        return tempDiv.textContent || tempDiv.innerText || '';
    };

    const currentLang = activeLanguage === 'el' ? 'el' : 'en';
    const title = activeLanguage === 'el' ? newsItem?.grTitle : newsItem?.enTitle;
    const description = activeLanguage === 'el' ? newsItem?.grDescription : newsItem?.enDescription;

    return (
        <>
            <MetatagsHelmet 
            activeLanguage={activeLanguage}
            pageTitle={metadata.title}
            pageDescription={metadata.description}
            additionalKeywords={metadata.keywords}
            pagePath={`news/${slug}`}
            />
            <SectionWrapper className='pt-16'>
                {loading.current ?
                    <div className='max-w-1128 m-auto'>
                        <SkeletonLoader height={'54vh'} width={'1128px'} borderRadius={14} />
                    </div>
                    :
                    <div className='relative  text-white max-w-1128 mx-auto rounded-2xl mb-4'>
                        <img src={`data:image/jpeg;base64,${newsItem.image}`} alt="test" className='rounded-md' />
                        {/* <div className='absolute z-0 inset-0 bg-black bg-opacity-40 rounded-md'></div> */}

                    </div>

                }
                <SectionTitle className='!mb-1' 
                    title={activeLanguage === 'el' ? newsItem?.grTitle : newsItem?.enTitle}
                />
                <div className=' mb-4 xmd:mb-6 font-thin text-base leading-8 w-full xmd:min-w-[400px] xmd:w-[32vw]'>
                    {newsItem?.date ? new Date(newsItem.date).toLocaleDateString('en-GB') : ''}
                </div>
                <div className='max-w-1128 m-auto xmd:pt-6 xmd:pb-28 min-h-[40vh]'>
                    {loading.current ?
                        <div className='flex flex-col gap-1.5'>
                            <SkeletonLoader height={'16px'} width={'1128px'} borderRadius={4} />
                            <SkeletonLoader height={'16px'} width={'1128px'} borderRadius={4} />
                            <SkeletonLoader height={'16px'} width={'1128px'} borderRadius={4} />
                            <SkeletonLoader height={'16px'} width={'1128px'} borderRadius={4} />
                            <SkeletonLoader height={'16px'} width={'1128px'} borderRadius={4} />
                            <SkeletonLoader height={'16px'} width={'1128px'} borderRadius={4} />
                            <SkeletonLoader height={'16px'} width={'68%'} borderRadius={4} />
                        </div>
                        :
                        <HTMLRenderer htmlContent={activeLanguage === 'el' ? newsItem?.grDescription : newsItem?.enDescription} />
                    }
                </div>
            </SectionWrapper>
        </>
    )
}

export default SingleNewsPage;