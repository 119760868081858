import React, { useEffect, useState, useRef } from 'react';
import DriverHeader from '../../Components/Driver/DriverHeader/DriverHeader';
import DefaultLanguageInterface from '../../interfaces';
import MetatagsHelmet from '../../utils/MetatagsHelmet/MetatagsHelmet';
import DriverReasons from '../../Components/Driver/DriverReasons/DriverReasons';
import DownloadOurApp from '../../Components/Home/DownloadOurApp/DownloadOurApp';
import DriverCarousel from '../../Components/Driver/DriverCarousel/DriverCarousel';

const Contact: React.FC<DefaultLanguageInterface> = ({ activeLanguage }) => {
  const [isInView, setIsInView] = useState(false);
  const carouselRef = useRef<HTMLDivElement | null>(null);
  const metadata = {
    el: {
    title: "taxi.gr - Οδήγησε μαζί μας | Κέρδισε έως και 7€ επιπλέον ανά διαδρομή",
    description: "Κάνε εγγραφή στο taxi.gr για 24/7 υποστήριξη, ανταγωνιστικές απολαβές και μέγιστη ευελιξία, χωρίς αποκλειστικότητες. Προσφέρουμε άμεσες κρατήσεις και ραντεβού, καθώς και αναλυτικές οδηγίες πλοήγησης ώστε να σε διευκολύνουμε σε κάθε σου βήμα.",
    keywords: ["οδηγός", "οδηγός taxi", "οδηγός ταξί", "οδήγησε ταξί", "οδήγησε taxi", "οδηγός εφαρμογή", "οδηγός εφαρμογή ταξί"]
    },
    en: {
    title: "taxi.gr - Drive with us | Earn up to 7€ extra per trip",
    description: "Register with taxi.gr for 24/7 support, competitive earnings and maximum flexibility, without exclusivity requirements. We offer immediate bookings and appointments, as well as detailed navigation instructions to assist you at every step.",
    keywords: ["driver", "taxi driver", "taxi driver", "drive taxi", "drive taxi", "driver app", "taxi driver app"]
    }
};

  useEffect(() => {
    window.scrollTo(0, 0);
    
    const observer = new IntersectionObserver(
      ([entry]) => {
        if (entry.isIntersecting) {
          setIsInView(true);
          observer.disconnect();
        }
      },
      { threshold: 0.1 }
    );

    if (carouselRef.current) {
      observer.observe(carouselRef.current);
    }

    return () => {
      if (carouselRef.current) {
        observer.unobserve(carouselRef.current);
      }
    };
  }, []);

  const currentLang = activeLanguage as keyof typeof metadata;

  return (
    <>
      <MetatagsHelmet 
      activeLanguage={activeLanguage}
      pageTitle={metadata[currentLang].title}
      pageDescription={metadata[currentLang].description}
      additionalKeywords={metadata[currentLang].keywords}
      pagePath="/driver"
      />
      <main>
        <DriverHeader activeLanguage={activeLanguage} />
        <DriverReasons activeLanguage={activeLanguage} />
        <div ref={carouselRef}>
          {isInView && <DriverCarousel activeLanguage={activeLanguage} />} {/* Render only when in view */}
        </div>
        {/* <ToPassenger activeLanguage={activeLanguage} /> */}
        {/* <DriverHowItWorks activeLanguage={activeLanguage} /> */}
        {/* <HomeDriver activeLanguage={activeLanguage} buttonText='Κάνε εγγραφή' buttonClickLink={`${activeLanguage == 'en' ? '/en' : ''}/drivers-form`}/> */}
        <DownloadOurApp activeLanguage={activeLanguage} selectedMode={'driver'} />
      </main>
    </>
  );
};

export default Contact;