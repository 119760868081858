import React from "react";
import DefaultLanguageInterface from "../../interfaces";
import MetatagsHelmet from '../../utils/MetatagsHelmet/MetatagsHelmet';
import DriverCommonQuestions from "../../Components/Driver/DriverCommonQuestions/DriverCommonQuestions";

const DriverCommonQuestionsPage:React.FC<DefaultLanguageInterface> = ({activeLanguage}) => {
    const metadata = {
        el: {
            title: "taxi.gr - Συχνές Ερωτήσεις Οδηγών | Όλα όσα χρειάζεται να γνωρίζετε",
            description: "Βρείτε απαντήσεις σε όλες τις ερωτήσεις σας σχετικά με τις αμοιβές, τις προϋποθέσεις συνεργασίας, τη χρήση της εφαρμογής και άλλα θέματα οδηγών. Το taxi.gr προσφέρει 24/7 υποστήριξη και όλες τις πληροφορίες που χρειάζεστε για να ξεκινήσετε και να μεγιστοποιήσετε τα κέρδη σας.",
            keywords: ["συχνές ερωτήσεις οδηγών", "οδηγός ταξί", "αμοιβές οδηγών", "εγγραφή οδηγού", "απαιτήσεις οδηγού", "εφαρμογή οδηγού", "πληρωμές οδηγών", "υποστήριξη οδηγών", "taxi.gr οδηγοί", "συνεργασία με taxi.gr", "ωράριο οδηγών", "οδηγίες εφαρμογής"]
        },
        en: {
            title: "taxi.gr - Driver FAQ | Everything you need to know",
            description: "Find answers to all your questions about earnings, partnership requirements, app usage and other driver-related topics. taxi.gr offers 24/7 support and all the information you need to get started and maximize your earnings.",
            keywords: ["driver faq", "taxi driver", "driver earnings", "driver registration", "driver requirements", "driver app", "driver payments", "driver support", "taxi.gr drivers", "partnership with taxi.gr", "driver schedule", "app instructions"]
        }
      };
      
      const currentLang = activeLanguage as keyof typeof metadata;

      return (
        <>
            <MetatagsHelmet 
            activeLanguage={activeLanguage}
            pageTitle={metadata[currentLang].title}
            pageDescription={metadata[currentLang].description}
            additionalKeywords={metadata[currentLang].keywords}
            pagePath="driver-faqs"
            />
        <main>
            <DriverCommonQuestions activeLanguage={activeLanguage} />
        </main>
        </>
    )
}

export default DriverCommonQuestionsPage;