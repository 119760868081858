import React from 'react';

interface LogoSvgProps {
    fill: string;
    className?: string;
    pathStyle?: string;
}

const LogoSvg: React.FC<LogoSvgProps> = ({ fill, className, pathStyle }) => {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" id="Layer_2" data-name="Layer 2" viewBox="0 0 859.58 306.19" className={className}>
            <g id="Layer_1-2" data-name="Layer 1">
                <g>
                    <path className={pathStyle} fill={fill} d="m2.89,29.48C1.29,29.49,0,30.79,0,32.39v136.28c.01,17.83,4.45,31.75,13.2,41.39,8.93,9.84,21.96,14.82,38.72,14.82,11.08,0,22.17-2.42,32.96-7.18.54-.22,3.12-1.32,10.74-5.84l-.09-.13-16.83-31.92h0s-.04-.07-.04-.07c-13.11,6.73-20.15,6.76-23.16,6.77h-.46c-7.64.12-10.03-2.74-10.8-3.68-2.37-2.89-3.56-7.64-3.56-14.15v-58.52c0-3.2,2.6-5.8,5.8-5.8h34.35c1.61,0,2.91-1.3,2.91-2.91v-31.71c-.01-1.61-1.31-2.91-2.92-2.91h-34.34c-3.2.01-5.8-2.59-5.8-5.79v-28.82c0-1.61-1.31-2.91-2.92-2.91l-34.87.18Z"></path>
                    <path className={pathStyle} fill={fill} d="m857.67,77.07c-4.9-4-10.88-7.18-17.81-9.45-6.81-2.23-14.03-3.37-21.45-3.37-10.62,0-20.28,2.5-28.71,7.43-1.46.85-2.92,1.81-4.33,2.83h0c-1.91,1.39-4.59.04-4.62-2.32l-.03-2.49c-.02-1.59-1.31-2.87-2.91-2.87h-29.84c-1.61,0-2.91,1.3-2.91,2.91v150.23c0,1.61,1.3,2.91,2.91,2.91h32.76c1.61,0,2.91-1.3,2.91-2.91v-73.97c0-12.77,3.17-23.07,9.41-30.61,5.99-7.24,13.81-10.76,23.92-10.76,6.28,0,12.21,1.63,18.04,4.98,1.27.73,2.88.39,3.76-.78l20.22-27.07c.93-1.24.72-2.98-.47-3.97l-.86-.71Z"></path>
                    <path className={pathStyle} fill={fill} d="m471.15,0c-13.83,0-25.08,11.25-25.08,25.08s11.25,25.08,25.08,25.08,25.08-11.25,25.08-25.08S484.98,0,471.15,0Z"></path>
                    <circle className={pathStyle} fill={fill} cx="536.86" cy="223.99" r="25.08"></circle>
                    <rect className={pathStyle} fill={fill} x="450.68" y="66.83" width="40.96" height="156.04" rx="2.91" ry="2.91"></rect>
                    <path className={pathStyle} fill={fill} d="m355.95,110.93l-7.13-10.53-22.56-32.32c-.54-.78-1.43-1.24-2.38-1.24h-42.32c-2.37,0-3.74,2.69-2.36,4.61l48.49,67.22c1.45,2.01,1.47,4.73.03,6.76l-51.61,72.87c-1.36,1.93.01,4.59,2.37,4.59h42.56c.96,0,1.86-.48,2.4-1.27l30.09-44.33c1.15-1.7,3.66-1.7,4.81,0l30.09,44.33c.54.8,1.44,1.27,2.4,1.27h42.56c2.36,0,3.74-2.66,2.37-4.59l-51.61-72.87c-1.44-2.03-1.43-4.75.03-6.76l48.49-67.21c1.39-1.92.01-4.61-2.36-4.61h-42.32c-.95,0-1.84.46-2.38,1.24l-22.58,32.35"></path>
                    <path className={pathStyle} fill={fill} d="m638.47,306.19c10.1,0,20.01-1.55,29.46-4.6,34.8-11.23,56.09-39.9,56.93-76.7V68.92c0-1.61-1.3-2.91-2.91-2.91h-31.94c-1.59,0-2.89,1.28-2.91,2.88l-.05,5.76c-.01,1.14-1.26,1.82-2.22,1.22l-2.2-1.38c-2.65-1.66-5.48-3.19-8.41-4.53-10.27-4.72-21.8-7.11-34.26-7.11-15.01,0-28.58,3.52-40.35,10.46-11.8,6.95-21.15,16.72-27.78,29.03-6.56,12.19-9.89,26.22-9.89,41.7s3.33,29.5,9.89,41.69c6.63,12.31,15.97,22.08,27.77,29.03,11.77,6.94,25.35,10.46,40.36,10.46,12.46,0,23.99-2.39,34.26-7.11,2.93-1.34,5.76-2.87,8.41-4.53l2.19-1.37c.96-.6,2.21.08,2.22,1.21l.16,11.31v.08c-1.26,28.55-21.94,38.26-30.77,41.11-22.32,7.2-48.11.16-60.02-16.37l-.12-.17-30.4,21.91.11.16c15.44,21.43,43.21,34.74,72.49,34.74Zm47.81-158.27c-1.66,20.81-18.6,37.75-39.41,39.41-1.15.09-2.31.14-3.45.14-11.41,0-22.36-4.5-30.48-12.62-8.94-8.94-13.49-21.31-12.48-33.93,1.66-20.81,18.6-37.75,39.41-39.41,12.6-1,24.99,3.54,33.93,12.48,8.94,8.94,13.49,21.31,12.48,33.94Z"></path>
                    <path className={pathStyle} fill={fill} d="m254.86,66.83h-31.88c-1.61,0-2.91,1.3-2.91,2.91v8.11l-.15.21-4.39-2.74c-2.66-1.66-5.49-3.19-8.41-4.53-10.27-4.72-21.8-7.11-34.26-7.11-15.01,0-28.58,3.52-40.35,10.46-11.8,6.95-21.15,16.72-27.78,29.02-6.57,12.2-9.89,26.22-9.89,41.7s3.33,29.5,9.89,41.69c6.63,12.31,15.97,22.08,27.77,29.03,11.78,6.94,25.35,10.46,40.36,10.46,12.46,0,23.99-2.39,34.26-7.11,2.93-1.35,5.76-2.87,8.42-4.53l4.38-2.74.16.19v8.12c0,1.61,1.3,2.91,2.91,2.91h31.88c1.61,0,2.91-1.3,2.91-2.91V69.74c0-1.61-1.3-2.91-2.91-2.91Zm-78.59,121.45c-23.74,0-43.05-19.31-43.05-43.05s19.31-43.05,43.05-43.05,43.05,19.31,43.05,43.05-19.31,43.05-43.05,43.05Z"></path>
                </g>
            </g>
        </svg>
    )
}
export default LogoSvg;