import React from 'react';
import PrivacyPolicyHero from '../../../Components/Misc/PrivacyPolicy/PrivacyPolicyHero/PrivacyPolicyHero';
import PrivacyPolicyContent from '../../../Components/Misc/PrivacyPolicy/PrivacyPolicyContent/PrivacyPolicyContent';
import DefaultLanguageInterface from "../../../interfaces";
import MetatagsHelmet from '../../../utils/MetatagsHelmet/MetatagsHelmet';


const PrivacyPolicyPage:React.FC<DefaultLanguageInterface> = (props) => {
    const {activeLanguage} = props;
    const metadata = {
        el: {
            title: "taxi.gr - Πολιτική Απορρήτου | Προστασία Προσωπικών Δεδομένων",
            description: "Ενημερωθείτε για τον τρόπο που συλλέγουμε, επεξεργαζόμαστε και προστατεύουμε τα προσωπικά σας δεδομένα στο taxi.gr. Η πολιτική απορρήτου μας περιγράφει αναλυτικά τα δικαιώματά σας, τις πρακτικές μας για την ασφάλεια των δεδομένων και τη συμμόρφωσή μας με τον GDPR.",
            keywords: ["πολιτική απορρήτου", "προσωπικά δεδομένα", "προστασία δεδομένων", "GDPR", "απόρρητο ταξί", "ασφάλεια δεδομένων", "cookies", "δικαιώματα χρήστη", "taxi.gr δεδομένα"]
        },
        en: {
            title: "taxi.gr - Privacy Policy | Personal Data Protection",
            description: "Learn how we collect, process and protect your personal data at taxi.gr. Our privacy policy details your rights, our data security practices, and our compliance with GDPR regulations.",
            keywords: ["privacy policy", "personal data", "data protection", "GDPR", "taxi privacy", "data security", "cookies", "user rights", "taxi.gr data"]
        }
    }
    
    const currentLang = activeLanguage as keyof typeof metadata;

    return (
        <>
        <MetatagsHelmet 
        activeLanguage={activeLanguage}
        pageTitle={metadata[currentLang].title}
        pageDescription={metadata[currentLang].description}
        additionalKeywords={metadata[currentLang].keywords}
        pagePath="/privacy-policy"
        />
            <main>
                <PrivacyPolicyHero activeLanguage={activeLanguage}/>
                <PrivacyPolicyContent activeLanguage={activeLanguage}/>
            </main>
        </>
    )

}


export default PrivacyPolicyPage;