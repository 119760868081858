import React from "react";
import PassengerCommonQuestions from "../../../Components/Passenger/PassengerCommonQuestions/PassengerCommonQuestions";
import DefaultLanguageInterface from "../../../interfaces";
import MetatagsHelmet from '../../../utils/MetatagsHelmet/MetatagsHelmet';

const PassengerFAQPage: React.FC<DefaultLanguageInterface> = (props) => {
    const { activeLanguage } = props;
    const metadata = {
        el: {
            title: "taxi.gr - Συχνές Ερωτήσεις Επιβατών | Όλα όσα θέλετε να μάθετε",
            description: "Βρείτε απαντήσεις σε όλες τις ερωτήσεις σας σχετικά με κρατήσεις ταξί, τιμές, τρόπους πληρωμής, ασφάλεια και περισσότερα. Το taxi.gr σας προσφέρει εύκολη και αξιόπιστη μετακίνηση με πλήρη διαφάνεια σε κάθε βήμα της διαδρομής σας.",
            keywords: ["συχνές ερωτήσεις ταξί", "κράτηση ταξί", "τιμές ταξί", "ασφάλεια επιβατών", "πληρωμή ταξί", "εφαρμογή ταξί", "μετακίνηση", "taxi.gr εφαρμογή", "ακύρωση διαδρομής", "παραλαβή ταξί", "ερωτήσεις επιβατών", "επιβάτης"]
            },
        en: {
            title: "taxi.gr - Passenger FAQ | Everything you need to know",
            description: "Find answers to all your questions about taxi bookings, pricing, payment methods, safety and more. taxi.gr offers you easy and reliable transportation with complete transparency at every step of your journey.",
            keywords: ["taxi faq", "taxi booking", "taxi prices", "passenger safety", "taxi payment", "taxi app", "transportation", "taxi.gr app", "ride cancellation", "taxi pickup", "passenger questions", "passenger"]
        }
      };

    const currentLang = activeLanguage as keyof typeof metadata;

    return (
        <>
            <MetatagsHelmet 
            activeLanguage={activeLanguage}
            pageTitle={metadata[currentLang].title}
            pageDescription={metadata[currentLang].description}
            additionalKeywords={metadata[currentLang].keywords}
            pagePath="passenger"
            />
        <main>
            <PassengerCommonQuestions activeLanguage={activeLanguage} />
        </main>
        </>
    )
}

export default PassengerFAQPage;